import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import Title from "../../components/admin-components/Title"
import { Button } from "../../components/Button"
import { useEffect, useState } from "react"
import { addInstitutionProfileApi, getInstitutionProfileApi } from "../../apis/api"
import toast from "react-hot-toast"
import { ErrorHandler } from "../../components/error/errorHandler"

export const InstitutionProfile = () => {
    const [email, setEmail] = useState('')
    const [location, setLocation] = useState('')
    const [contact, setContact] = useState('')
    const [facebookLink, setFacebookLink] = useState('')
    const [locationForMap, setLocationForMap] = useState('')
    const [yearsOfExcellence, setYearsOfExcellence] = useState('')
    const [noOfStaffs, setNoOfStaffs] = useState('')
    const [totalStudents, setTotalStudents] = useState('')
    const [updated, setUpdated] = useState(false)

    const institutionProfileSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Required"),
        location: Yup.string().required("Required"),
        number: Yup.string().required("Required"),
        facebookLink: Yup.string().required("Required"),
        locationForMap: Yup.string().required("Required"),
        yearsOfExcellence: Yup.string().required("Required"),
        noOfStaffs: Yup.string().required("Required"),
        totalStudents: Yup.string().required("Required"),
    })

    useEffect(() => {
        getInstitutionProfileApi().then((res) => {
            if (res.data.success === true) {
                setEmail(res?.data?.result?.email)
                setLocation(res?.data?.result?.location)
                setContact(res?.data?.result?.number)
                setFacebookLink(res?.data?.result?.facebookLink)
                setLocationForMap(res?.data?.result?.locationForMap)
                setYearsOfExcellence(res?.data?.result?.yearsOfExcellence)
                setNoOfStaffs(res?.data?.result?.noOfStaffs)
                setTotalStudents(res?.data?.result?.totalStudents)
            }
        })
    }, [updated])

    function updateInstitutionProfile(values) {
        addInstitutionProfileApi(values).then((res) => {
            if (res.data.success === true) {
                toast.success(res.data.message)
                setUpdated((prev) => !prev)
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }

    return (
        <>
            <main className="p-4">
                <div className="flex flex-col gap-2">
                    <Title title="Change Institution Settings" />
                    <p>By changing this settings it will affect everywhere.</p>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        email: email,
                        location: location,
                        number: contact,
                        facebookLink: facebookLink,
                        locationForMap: locationForMap,
                        yearsOfExcellence: yearsOfExcellence,
                        noOfStaffs: noOfStaffs,
                        totalStudents: totalStudents,
                    }}
                    validationSchema={institutionProfileSchema}
                    onSubmit={(values) => {
                        updateInstitutionProfile(values)
                    }}
                >
                    {(props) => (
                        <Form className="flex flex-col gap-4 py-8">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="email"
                                        className="block font-medium"
                                    >
                                        Email
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="email"
                                            name="email"
                                            id="email"
                                            autoComplete="email"
                                            placeholder="Email"
                                            className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.email && props.touched.email && (
                                        <p className="text-red-500 text-sm">{props.errors.email}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="location"
                                        className="block font-medium"
                                    >
                                        Location
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="text"
                                            name="location"
                                            id="location"
                                            placeholder="Location"
                                            className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.location && props.touched.location && (
                                        <p className="text-red-500 text-sm">{props.errors.location}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="number"
                                        className="block font-medium"
                                    >
                                        Number
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="tel"
                                            name="number"
                                            id="number"
                                            autoComplete="number"
                                            placeholder="Number"
                                            className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.contact && props.touched.contact && (
                                        <p className="text-red-500 text-sm">{props.errors.contact}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="facebookLink"
                                        className="block font-medium"
                                    >
                                        Facebook Link
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="text"
                                            name="facebookLink"
                                            id="facebookLink"
                                            autoComplete="Facebook Link"
                                            placeholder="Facebook Link"
                                            className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.facebookLink && props.touched.facebookLink && (
                                        <p className="text-red-500 text-sm">{props.errors.facebookLink}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="yearsOfExcellence"
                                        className="block font-medium"
                                    >
                                        Years of Excellence
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="number"
                                            name="yearsOfExcellence"
                                            id="yearsOfExcellence"
                                            placeholder="Years of Excellence"
                                            className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.yearsOfExcellence && props.touched.yearsOfExcellence && (
                                        <p className="text-red-500 text-sm">{props.errors.yearsOfExcellence}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="noOfStaffs"
                                        className="block font-medium"
                                    >
                                        No. of Staffs
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="number"
                                            name="noOfStaffs"
                                            id="noOfStaffs"
                                            placeholder="No. of Staffs"
                                            className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.noOfStaffs && props.touched.noOfStaffs && (
                                        <p className="text-red-500 text-sm">{props.errors.noOfStaffs}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="totalStudents"
                                        className="block font-medium"
                                    >
                                        Total Students
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="number"
                                            name="totalStudents"
                                            id="totalStudents"
                                            placeholder="Total Students"
                                            className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.totalStudents && props.touched.totalStudents && (
                                        <p className="text-red-500 text-sm">{props.errors.totalStudents}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-3">
                                    <label
                                        htmlFor="locationForMap"
                                        className="block font-medium"
                                    >
                                        Location For Map
                                    </label>
                                    <div className="flex flex-col gap-2">
                                        <Field
                                            type="text"
                                            name="locationForMap"
                                            id="locationForMap"
                                            placeholder="Location For Map in URL"
                                            className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                        {props.errors.locationForMap && props.touched.locationForMap && (
                                            <p className="text-red-500 text-sm">{props.errors.locationForMap}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Button className={"w-max btn-primary"} type="submit">Save</Button>
                        </Form>
                    )}
                </Formik>
            </main>
        </>
    )
}