export const ContactSection = ({ institutionProfile }) => {
    return (
        <>
            <div className="flex flex-col lg:flex-row" id="contact">
                <div id="mapCon" className="lg:w-1/2">
                    <iframe
                        className="w-full h-full"
                        src={institutionProfile?.locationForMap}
                    ></iframe>
                </div>
                <div className="custom-blue lg:w-1/2 p-6 lg:p-12 font-bold text-white">
                    <h2 className="text-2xl">Contact Us</h2>
                    <div className="m-4 ml-0 flex flex-row items-center">
                        <span className="mr-4">
                            <img src="assets/images/Vector.png" alt="Email Icon" className="h-6 w-6" />
                        </span>
                        <div>
                            <p>Email</p>
                            <p className="">{institutionProfile?.email}</p>
                        </div>
                    </div>
                    <div className="m-4 ml-0 flex flex-row items-center">
                        <span className="mr-4">
                            <img
                                src="assets/images/location.svg"
                                alt="Location Icon"
                                className="h-6 w-6"
                            />
                        </span>
                        <div>
                            <p>Location</p>
                            <p className="">{institutionProfile?.location}</p>
                        </div>
                    </div>
                    <div className="m-4 ml-0 flex flex-row items-center">
                        <span className="mr-4">
                            <img src="assets/images/phone.svg" alt="Phone Icon" className="h-6 w-6" />
                        </span>
                        <div>
                            <p>Phone Number</p>
                            <p className="">{institutionProfile?.number}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}