import { useEffect, useState } from "react";
import { getFaqsApi } from "../../../../apis/api";
import { ErrorHandler } from "../../../../components/error/errorHandler";

export const FAQsSection = () => {
    const [faqs, setFaqs] = useState([])
    function toggleAnswer(id) {
        const answer = document.querySelector(`#faq-answer-${id}`);
        const icon = document.querySelector(`#toggle-icon-${id} i`);
        const question = document.querySelector(`#faq-q-${id}`);

        answer.classList.toggle("hidden");

        if (answer.classList.contains("hidden")) {
            icon.classList.remove("fa-minus");
            icon.classList.add("fa-plus");
            question.style.backgroundColor = "white";
            answer.style.backgroundColor = "white";
            question.style.color = "black";
            answer.style.color = "black";
            icon.style.color = "black";
        } else {
            icon.classList.remove("fa-plus");
            icon.classList.add("fa-minus");
        }
    }

    useEffect(() => {
        getFaqsApi().then((res) => {
            if (res?.data?.success === true) {
                setFaqs(res?.data?.result)
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }, [])
    return (
        <>
            <div className="container mx-auto faq-sec" id="faq">
                <div className="cur-header mb-6">
                    <p className="text-xl font-semibold">Frequently Asked Questions</p>
                    <div
                        style={{ width: "120px", borderColor: "#ff6d9d" }}
                        className="flex justify-center items-center relative border mt-3"
                    >
                        <div
                            className="w-1/2 h-1 absolute"
                            style={{ backgroundColor: "#ff6d9d" }}
                        ></div>
                    </div>
                </div>
                <div
                    className="w-full flex flex-col md:flex-row justify-center gap-10 items-center mx-auto"
                >
                    <div className="faq_img">
                        <img src="assets/images/Faqs.png" alt="..." />
                    </div>
                    <div className="md:w-1/2 rounded-lg">
                        {
                            faqs && faqs.length > 0 ? faqs.map((faq, index) => (
                                <div className="faq_box">
                                    <div
                                        id={`faq-q-${index + 1}`}
                                        className="px-2 py-2 cursor-pointer mt-4 font-bold"
                                        onClick={() => toggleAnswer(index + 1)}
                                    >
                                        <span className="text-lg">{faq?.question}</span>
                                        <div id={`toggle-icon-${index + 1}`} className="toggle-icon float-right">
                                            <i
                                                className="fa-solid fa-plus text-black"
                                                style={{ color: "#000000" }}
                                            ></i>
                                        </div>
                                    </div>
                                    <div id={`faq-answer-${index + 1}`} className="faq-answer hidden px-2 py-2">
                                        <p>{faq?.answer}</p>
                                    </div>
                                </div>
                            )) :
                                null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}