import axios from "axios";

const Api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
})

const ApiWithFormData = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Content-Type": "multipart/form-data",
    },
})

const token = localStorage.getItem('_mountview_token_')

const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
}

// login api for only admin
export const loginAdminApi = (data) => Api.post('/api/auth/login', data);

// get admin by id
export const getAdminByIdApi = (id) => Api.get(`/api/auth/getAdmin/${id}`);

// add or update about us content Api
export const addAboutUsApi = (data) => Api.post('/api/admin/aboutus/add', data, config);

// get aboutUs content
export const getAboutUsApi = () => Api.get('/api/admin/aboutus/get');

// add or update instution profile Api
export const addInstitutionProfileApi = (data) => Api.post('/api/admin/institutionprofile/add', data, config);

// get instution profile
export const getInstitutionProfileApi = () => Api.get('/api/admin/institutionprofile/get');

// add or update awards content Api
export const addAwardContentApi = (formData) => ApiWithFormData.post('/api/admin/awards/add', formData, config);

// get awards content
export const getAwardContentApi = () => Api.get('/api/admin/awards/get');

// add or update achievements content Api
export const addAchievementsApi = (formData) => ApiWithFormData.post('/api/admin/achievements/add', formData, config);

// get achievements content
export const getAchievementsApi = () => Api.get('/api/admin/achievements/get');

// add principal message content Api
export const addPrincipalMessageApi = (data) => ApiWithFormData.post('/api/admin/principalmessage/add', data, config);

// get principal message content
export const getPrincipalMessageApi = () => Api.get('/api/admin/principalmessage/get');

// add youtube Link Api
export const addYoutubeLinkApi = (data) => Api.post('/api/admin/youtubelink/add', data, config);

// get youtube Link Api
export const getYoutubeLinkApi = () => Api.get('/api/admin/youtubelink/get');

// add faqs by admin Api
export const addFaqsApi = (data) => Api.post('/api/admin/faqs/add', data, config);

// get faqs by admin Api
export const getFaqsApi = () => Api.get('/api/admin/faqs/get');

// update faqs by admin Api
export const updateFaqsApi = (id, data) => Api.put(`/api/admin/faqs/update/${id}`, data, config);

// delete faqs by admin Api
export const deleteFaqsApi = (id) => Api.delete(`/api/admin/faqs/delete/${id}`, config)

// add curriculum by admin Api
export const addCurriculumApi = (data) => ApiWithFormData.post('/api/admin/curriculum/add', data, config);

// get curriculum by admin Api
export const getCurriculumApi = () => Api.get('/api/admin/curriculum/get');

// update curriculum by admin Api
export const updateCurriculumApi = (id, data) => ApiWithFormData.put(`/api/admin/curriculum/update/${id}`, data, config);

// delete curriculum by admin Api
export const deleteCurriculumApi = (id) => Api.delete(`/api/admin/curriculum/delete/${id}`, config)

// add glimpses by admin Api
export const addGlimpseApi = (data) => ApiWithFormData.post('/api/admin/glimpses/add', data, config);

// get glimpse
export const getGlimpseApi = (page, limit) => Api.get(`/api/admin/glimpses/get?page=${page}&limit=${limit}`);

// update glimpse Status
export const updateGlimpseStatusApi = (id, data) => Api.put(`/api/admin/glimpses/update/${id}`, data, config)

// delete glimpse
export const deleteGlimpseApi = (id) => Api.delete(`/api/admin/glimpses/delete/${id}`, config)

// get glimpses for user
export const getGlimpseForUserApi = () => Api.get('/api/user/glimpses/get', config)