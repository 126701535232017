import { Toaster } from "react-hot-toast";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import "./App.css";
import { Footer } from "./components/footer/Footer";
import { Navbar } from "./components/navbar/Navbar";
import { AboutUs } from "./pages/admin/AboutUs";
import { Achievements } from "./pages/admin/Achievements";
import { Awards } from "./pages/admin/Awards";
import { Curriculum } from "./pages/admin/Curriculum";
import { FAQs } from "./pages/admin/FAQs";
import { Glimpses } from "./pages/admin/Glimpses";
import { InstitutionProfile } from "./pages/admin/InstitutionProfile";
import { PrincipalMessage } from "./pages/admin/PrincipalMessage";
import { YoutubeLink } from "./pages/admin/YoutubeLink";
import { Login } from "./pages/authentication/Login";
import { Landing } from "./pages/user/landing/Landing";
import AdminRoutes from "./protected/AdminRoutes";
import { useEffect, useState } from "react";
import { getInstitutionProfileApi } from "./apis/api";

function App() {
  const [institutionprofile, setInstitutionProfile] = useState({});

  useEffect(() => {
    getInstitutionProfileApi().then((res) => {
      if (res.data.success === true) {
        setInstitutionProfile(res?.data?.result);
      }
    });
  }, []);
  return (
    <Router>
      <Toaster
        containerClassName="custom-toaster-style"
        position="top-center"
        reverseOrder={false}
      />
      <div className="flex flex-col w-full min-h-screen">
        <Navbar institutionProfile={institutionprofile} />
        <div className="flex-1">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Landing institutionProfile={institutionprofile} />} />
            <Route element={<AdminRoutes />}>
              <Route
                path="/admin/dashboard"
                element={<AdminLayout children={<InstitutionProfile />} />}
              />
              <Route
                path="/admin/aboutus"
                element={<AdminLayout children={<AboutUs />} />}
              />
              <Route
                path="/admin/awards"
                element={<AdminLayout children={<Awards />} />}
              />
              <Route
                path="/admin/achievements"
                element={<AdminLayout children={<Achievements />} />}
              />
              <Route
                path="/admin/curriculum"
                element={<AdminLayout children={<Curriculum />} />}
              />
              <Route
                path="/admin/principal-message"
                element={<AdminLayout children={<PrincipalMessage />} />}
              />
              <Route
                path="/admin/youtube-link"
                element={<AdminLayout children={<YoutubeLink />} />}
              />
              <Route
                path="/admin/faqs"
                element={<AdminLayout children={<FAQs />} />}
              />
              <Route
                path="/admin/glimpses"
                element={<AdminLayout children={<Glimpses />} />}
              />
            </Route>
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
