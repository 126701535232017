import { Edit, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { deleteCurriculumApi, getCurriculumApi } from "../../apis/api";
import Title from "../../components/admin-components/Title";
import { ErrorHandler } from "../../components/error/errorHandler";
import { AddCurriculumModal } from "./curriculum-components/AddCurriculumModel";
import Swal from "sweetalert2";
import { EditCurriculumModal } from "./curriculum-components/EditCurriculumModal";

export const Curriculum = () => {
    const [updated, setUpdated] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [selectedCurriculum, setSelectedCurriculum] = useState(null)
    const [curriculums, setCurriculums] = useState([])

    useEffect(() => {
        getCurriculumApi().then((res) => {
            if (res?.data?.success === true) {
                setCurriculums(res?.data?.result)
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }, [updated])

    function deleteCurriculum(id) {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#003366',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteCurriculumApi(id).then((res) => {
                    if (res?.data?.success === true) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Curriculum deleted successfully',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        setUpdated((prev) => !prev)
                    }
                }).catch((err) => {
                    ErrorHandler(err)
                })
            }
        })
    }
    return (
        <>
            <main className="p-4">
                <div className="flex flex-col gap-2">
                    <Title title="All Curriculums" />
                    <p>All Curriculums will appear here.</p>
                </div>
                <div className="w-full flex item-center justify-end">
                    <button onClick={() => setShowAddModal(true)} className="btn-primary">Add Curriculum</button>
                </div>
                <div class="relative overflow-auto mt-10">
                    <table class="w-full text-sm text-left text-neutral-700 bg-white">
                        <thead class="bg-neutral-100 text-sm text-neutral-700 uppercase sticky top-0">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    S.N
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Icon
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Color
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Title
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Description
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                curriculums && curriculums.length > 0 ? curriculums?.map((curriculum, index) => (
                                    <tr class="border-b hover:bg-white">
                                        <td class="px-6 py-4">
                                            {index + 1}
                                        </td>
                                        <td class="px-6 py-4">
                                            <img className="w-[50px]" src={curriculum?.image} alt="Curriculum Icon" />
                                        </td>
                                        <td class="px-6 py-4">
                                            {curriculum?.bgColor}
                                        </td>
                                        <td class="px-6 py-4">
                                            {curriculum?.title}
                                        </td>
                                        <td class="px-6 py-4">
                                            {curriculum?.description}
                                        </td>
                                        <td class="px-6 py-4">
                                            <div className="flex items-center gap-2">
                                                <button onClick={() => { setSelectedCurriculum(curriculum); setShowEditModal(true) }} className="icon-primary bg-blue-600 hover:bg-blue-600"><Edit size={16} /></button>
                                                <button onClick={() => deleteCurriculum(curriculum?._id)} className="icon-primary bg-red-600 hover:bg-red-600"><Trash size={16} /></button>
                                            </div>
                                        </td>
                                    </tr>
                                )) :
                                    <tr class="border-b hover:bg-white">
                                        <td colSpan={7} className="text-center py-10 uppercase text-red-600 font-medium text-xl">No Curriculums Found</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </main>
            <AddCurriculumModal open={showAddModal} onClose={() => setShowAddModal(false)} setUpdated={setUpdated} />
            <EditCurriculumModal open={showEditModal} onClose={() => setShowEditModal(false)} setUpdated={setUpdated} selectedCurriculum={selectedCurriculum} />
        </>
    )
}