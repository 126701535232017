import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { getGlimpseForUserApi } from '../../../../apis/api';
import { ErrorHandler } from '../../../../components/error/errorHandler';

export const GlimpsesSection = () => {
    const [images, setImages] = useState([]);
    
    async function getAllGlimpses() {
        await getGlimpseForUserApi()
            .then((res) => {
                if (res?.data?.success) {
                    setImages(res?.data?.result);
                    console.log(res?.data?.result);
                }
            })
            .catch((err) => {
                ErrorHandler(err);
            });
    }

    useEffect(() => {
        getAllGlimpses();
    }, []);

    return (
        <div className="flex flex-col font-xl justify-center items-center mb-24 overflow-hidden">
            <h1 className="text-3xl m-4 font-bold text-center">Some of the Glimpses</h1>
            <div className="w-full bg-white rounded-lg">
                <OwlCarousel
                    key={images.length}
                    className="owl-theme"
                    loop
                    margin={10}
                    nav
                    dots={false}
                    autoplay
                    autoplayTimeout={2000}
                    responsive={{
                        0: {
                            items: 1,
                        },
                        600: {
                            items: 3,
                        },
                        1000: {
                            items: 5,
                        },
                    }}
                >
                    {images && images.length > 0 ? (
                        images.map((image, index) => (
                            <div className="item" key={index}>
                                <img
                                    className="w-70 h-80 rounded-2xl"
                                    src={image?.image}
                                    alt={`Image ${index + 1}`}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/path/to/placeholder/image.jpg';
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <div>No Images Available</div>
                    )}
                </OwlCarousel>
            </div>
        </div>
    );
};
