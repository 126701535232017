import { LogOut, Menu } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

export const AdminNavbar = () => {
    const location = useLocation()
    const [title, setTitle] = useState('')

    function logout() {
        Swal.fire({
            title: 'Are you sure you want to log out?',
            text: "You need to enter your credentials to login again!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#003366',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear()
                window.location.replace('/login')
            }
        })
    }

    useEffect(() => {
        if (location.pathname === '/admin/dashboard') {
            setTitle('Institution Profile')
        } else if (location.pathname === '/admin/aboutus') {
            setTitle('About Us')
        } else if (location.pathname === '/admin/awards') {
            setTitle('Awards')
        } else if (location.pathname === '/admin/achievements') {
            setTitle('Achievements')
        } else if (location.pathname === '/admin/curriculum') {
            setTitle('Curriculum')
        } else if (location.pathname === '/admin/stats') {
            setTitle('Institution Stats')
        } else if (location.pathname === '/admin/principal-message') {
            setTitle('Principal Message')
        } else if (location.pathname === '/admin/youtube-link') {
            setTitle('Youtube Link')
        } else if (location.pathname === '/admin/faqs') {
            setTitle('FAQs')
        } else if (location.pathname === '/admin/glimpses') {
            setTitle('Glimpses')
        }
    }, [location])

    return (
        <>
            <div className='w-full flex justify-between bg-gray-50 p-4'>
                <button className='lg:hidden'>
                    <Menu />
                </button>
                <h1 className='text-2xl font-medium'>{title}</h1>
                <button onClick={logout}><LogOut /></button>
            </div>
        </>
    )
}