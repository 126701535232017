import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import Title from "../../components/admin-components/Title"
import { Button } from "../../components/Button"
import { useEffect, useState } from "react"
import { addYoutubeLinkApi, getYoutubeLinkApi } from "../../apis/api"
import toast from "react-hot-toast"
import { ErrorHandler } from "../../components/error/errorHandler"

export const YoutubeLink = () => {
    const [youtubeLink,  setYoutubeLink] = useState("")
    const [updated, setUpdated] = useState(false)

    const linkSchema = Yup.object().shape({
        link: Yup.string().required("Required"),
    })

    async function addYoutubeLink(values) {
        await addYoutubeLinkApi(values).then((res) => {
            if (res.data.success === true) {
                toast.success(res.data.message)
            } else {
                toast.error(res.data.message)
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }

    useEffect(() => {
        getYoutubeLinkApi().then((res) => {
            setYoutubeLink(res?.data?.result?.link)
        })
    }, [updated])

    return (
        <>
            <main className="p-4">
                <div className="flex flex-col gap-2">
                    <Title title="Change Youtube Link" />
                    <p>By changing this settings it will affect everywhere.</p>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        link: youtubeLink,
                    }}
                    validationSchema={linkSchema}
                    onSubmit={(values) => {
                        addYoutubeLink(values)
                    }}
                >
                    {(props) => (
                        <Form className="flex flex-col gap-4 py-8">
                            <div className="grid gap-4">
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="link"
                                        className="block font-medium"
                                    >
                                        YouTube Link
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="text"
                                            name="link"
                                            id="link"
                                            placeholder="Enter YouTube Link"
                                            className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.link && props.touched.link && (
                                        <p className="text-red-500 text-sm">{props.errors.link}</p>
                                    )}
                                </div>
                                <Button className={'w-max btn-primary'}>Save</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </main>
        </>
    )
}