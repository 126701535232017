import { useEffect, useState } from "react"
import { AboutUsSection } from "./landing-components/AboutUsSection"
import { AwardSection } from "./landing-components/AwardSection"
import { ContactSection } from "./landing-components/ContactSection"
import { CountsSection } from "./landing-components/CountsSection"
import { CurriculumSection } from "./landing-components/CurriculumSection"
import { FAQsSection } from "./landing-components/FAQsSection"
import { GlimpsesSection } from "./landing-components/GlimpsesSection"
import { getPrincipalMessageApi, getYoutubeLinkApi } from "../../../apis/api"

export const Landing = ({ institutionProfile }) => {
    const [youtubeLink, setYoutubeLink] = useState("")
    const [responseImage, setResponseImage] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")

    useEffect(() => {
        getYoutubeLinkApi().then((res) => {
            setYoutubeLink(res?.data?.result?.link)
        })
    }, [])

    useEffect(() => {
        getPrincipalMessageApi().then((res) => {
            if (res.data.success === true) {
                setResponseImage(res?.data?.result?.image)
                setTitle(res?.data?.result?.title)
                setDescription(res?.data?.result?.description)
            }
        })
    }, [])
    return (
        <>
            <div className="hero-container">
                <div className="hero-text">
                    <p className="mb-3">ISO Certified Grade 'A' School</p>
                    <p id="main-text">
                        Education With <br />
                        Global Connection
                    </p>
                </div>
                <div>
                    <img src="assets/images/hero.png" className="hero-img" alt="Hero" />
                </div>
                <div id="about"></div>
            </div>
            <AboutUsSection />
            <AwardSection />
            <CurriculumSection />
            <CountsSection institutionProfile={institutionProfile} />
            <div className="award">
                <div className="award_cont" id="award_cont1">
                    <div className="award_img"><img src={responseImage} alt="Principal" /></div>
                    <div className="award_text">
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
            <div className="youtube_iframe">
                <iframe
                    className="w-full rounded-xl"
                    height="550"
                    src={youtubeLink}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>
            <FAQsSection />
            <GlimpsesSection />
            <ContactSection institutionProfile={institutionProfile} />
        </>
    )
}