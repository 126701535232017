import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import { updateCurriculumApi } from '../../../apis/api';
import { ErrorHandler } from '../../../components/error/errorHandler';
import { Modal } from '../../../components/Modal';

export const EditCurriculumModal = ({ open, onClose, setUpdated, selectedCurriculum }) => {
    const [image, setImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const curriculumSchema = Yup.object().shape({
        title: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        bgColor: Yup.string().required('Required'),
    })

    async function updateCurriculum(values) {
        const data = {
            title: values.title,
            description: values.description,
            bgColor: values.bgColor,
            image: image
        }
        await updateCurriculumApi(selectedCurriculum?._id, data).then((res) => {
            if (res.data.success === true) {
                toast.success(res.data.message)
                setUpdated((prev) => !prev)
                setImage(null)
                setPreviewImage(null)
                onClose()
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }

    return (
        <Modal modalTitle={'Edit Curriculum'} open={open} onClose={onClose}>
            <section className='w-full p-4'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        title: selectedCurriculum?.title,
                        description: selectedCurriculum?.description,
                        bgColor: selectedCurriculum?.bgColor
                    }}
                    validationSchema={curriculumSchema}
                    onSubmit={(values, { resetForm }) => {
                        updateCurriculum(values);
                        resetForm();
                    }}
                >
                    {(props) => (
                        <Form>
                            <div className="flex flex-col gap-2">
                                <label className='font-medium text-lg' htmlFor="title">Title</label>
                                <input
                                    className="shadow-sm appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="title"
                                    name="title"
                                    type="text"
                                    placeholder='Title'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.title}
                                />
                                {props.touched.title && props.errors.title ? (
                                    <div className="text-red-500 text-sm">{props.errors.title}</div>
                                ) : null}

                                <label className='font-medium text-lg mt-2' htmlFor="description">Description</label>
                                <Field
                                    as="textarea"
                                    className="shadow-sm appearance-none border rounded w-full min-h-24 p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="description"
                                    name="description"
                                    type="text"
                                    placeholder='Description'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.description}
                                />
                                {props.touched.description && props.errors.description ? (
                                    <div className="text-red-500 text-sm">{props.errors.description}</div>
                                ) : null}

                                <label className='font-medium text-lg mt-2' htmlFor="bgColor">BG Color</label>
                                <input type="color" id="bgColor" name="bgColor" className='w-20 h-10' onChange={props.handleChange} />
                                {props.touched.bgColor && props.errors.bgColor ? (
                                    <div className="text-red-500 text-sm">{props.errors.bgColor}</div>
                                ) : null}

                                <label className='font-medium text-lg mt-2' htmlFor="image">Image</label>
                                <input
                                    className="shadow-sm appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        setImage(file);
                                        setPreviewImage(URL.createObjectURL(file));
                                    }}
                                />
                                <div className="grid grid-cols-2 gap-5">
                                    {previewImage && (
                                        <>
                                            <p>Preview Image</p>
                                            <a className="w-max" target="_blank" href="">
                                                <img
                                                    src={previewImage}
                                                    alt="Preview"
                                                    className="h-32 w-56"
                                                />
                                            </a>
                                        </>
                                    )}
                                    {selectedCurriculum?.image && (
                                        <>
                                            <p>Current Image</p>
                                            <a className="w-max" target="_blank" href={selectedCurriculum?.image}>
                                                <img
                                                    src={selectedCurriculum?.image}
                                                    alt="Preview"
                                                    className="h-32 w-56"
                                                />
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                            <button type='submit' className='btn-primary mt-5'>Update</button>
                        </Form>
                    )}
                </Formik>
            </section>
        </Modal>
    );
}
