import { addGlimpseApi } from '../../../apis/api';
import { Modal } from '../../../components/Modal';
import { useState } from 'react';
import { ErrorHandler } from '../../../components/error/errorHandler';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import toast from 'react-hot-toast';

export const AddImageModal = ({ open, onClose, setUpdated }) => {
    const [images, setImages] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal);

    function handleImages(e) {
        const selectedFiles = Array.from(e.target.files);
        setImages((prevImages) => [...prevImages, ...selectedFiles]);
        const newPreviews = selectedFiles.map((file) => URL.createObjectURL(file));
        setPreviewImages((prevPreviews) => [...prevPreviews, ...newPreviews]);
    }

    async function addGlimpses() {
        setLoading(true);
        const formData = new FormData();
        images.forEach((image) => {
            formData.append('images', image);
        });
        try {
            const res = await addGlimpseApi(formData);
            if (res?.data?.success) {
                onClose(); 
                setImages([]); 
                setPreviewImages([]);
                toast.success(res?.data?.message);
                setUpdated((prev) => !prev);
            }
        } catch (err) {
            ErrorHandler(err);
        } finally {
            setLoading(false);
        }
    }

    function showLargePreview(imageUrl) {
        MySwal.fire({
            imageUrl: imageUrl,
            imageAlt: 'Preview Image',
            showCloseButton: true,
            showConfirmButton: false,
            width: '50%', 
        });
    }

    return (
        <Modal modalTitle={'Add Image'} open={open} onClose={onClose}>
            <section className='w-full p-4'>
                <div className="flex items-center justify-center w-full">
                    <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-50 hover:bg-neutral-100"
                    >
                        <div className="flex flex-col items-center justify-center p-8">
                            <p className='text-neutral-700 font-bold'>
                                Choose files / Drag and drop
                            </p>
                        </div>
                        <input
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            multiple 
                            onChange={handleImages} 
                        />
                    </label>
                </div>

                <div className="mt-5 grid grid-cols-3 gap-4">
                    {previewImages.map((preview, index) => (
                        <img
                            key={index}
                            src={preview}
                            alt={`Preview ${index}`}
                            className="w-full h-24 object-cover cursor-pointer"
                            onClick={() => showLargePreview(preview)}
                        />
                    ))}
                </div>

                <button
                    onClick={addGlimpses}
                    className="btn-primary mt-5"
                    disabled={loading || images.length === 0}
                >
                    {loading ? 'Uploading...' : 'Add'}
                </button>
            </section>
        </Modal>
    );
};
