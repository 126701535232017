import { Field, Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import { updateFaqsApi } from '../../../apis/api';
import { ErrorHandler } from '../../../components/error/errorHandler';
import { Modal } from '../../../components/Modal';

export const EditFAQsModal = ({ open, onClose, setUpdated, selectedFAQ }) => {
    const faqsSchema = Yup.object().shape({
        question: Yup.string().required('Required'),
        answer: Yup.string().required('Required'),
    })

    async function updateFaqs(values) {
        await updateFaqsApi(selectedFAQ?._id, values).then((res) => {
            if (res?.data?.success === true) {
                toast.success(res?.data?.message)
                setUpdated((prev) => !prev)
                onClose()
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }

    return (
        <Modal modalTitle={'Edit FAQs'} open={open} onClose={onClose}>
            <section className='w-full p-4'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        question: selectedFAQ?.question,
                        answer: selectedFAQ?.answer,
                    }}
                    validationSchema={faqsSchema}
                    onSubmit={(values) => {
                        updateFaqs(values);
                    }}
                >
                    {(props) => (
                        <Form>
                            <div className="flex flex-col gap-2">
                                <label className='font-medium text-lg' htmlFor="question">Question</label>
                                <input
                                    className="shadow-sm appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="question"
                                    name="question"
                                    type="text"
                                    placeholder='Question'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.question}
                                />
                                {props.touched.question && props.errors.question ? (
                                    <div className="text-red-500 text-sm">{props.errors.question}</div>
                                ) : null}

                                <label className='font-medium text-lg mt-2' htmlFor="answer">Answer</label>
                                <Field
                                    as="textarea"
                                    className="shadow-sm appearance-none border rounded w-full min-h-24 p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="answer"
                                    name="answer"
                                    type="text"
                                    placeholder='Answer'
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.answer}
                                />
                                {props.touched.answer && props.errors.answer ? (
                                    <div className="text-red-500 text-sm">{props.errors.answer}</div>
                                ) : null}
                            </div>
                            <button type='submit' className='btn-primary mt-5'>Update</button>
                        </Form>
                    )}
                </Formik>
            </section>
        </Modal>
    );
}
