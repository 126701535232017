import { useLocation } from "react-router-dom"

export const Footer = () => {
    const location = useLocation()
    const desiredPaths = ["/login", "/admin/dashboard", "/admin/aboutus", "/admin/awards", "/admin/achievements", "/admin/curriculum", "/admin/stats", "/admin/principal-message", "/admin/youtube-link", "/admin/faqs", "/admin/glimpses"];
    const isDesiredPath = desiredPaths.includes(location.pathname);
    if (isDesiredPath) {
        return null;
    }
    
    return (
        <>
            <footer className="w-full">
                <div className="bg-red-600">
                    <p className="font-medium py-2 text-sm text-white text-center">
                        Designed and Developed By
                        <a
                            className="hover:text-black pl-1"
                            target="_blank"
                            href="https://digitechnologynepal.com/"
                        >Digi Technology Nepal</a>
                    </p>
                </div>
            </footer>
        </>
    )
}