import { useEffect, useState } from "react"
import { getCurriculumApi } from "../../../../apis/api"
import { ErrorHandler } from "../../../../components/error/errorHandler"

export const CurriculumSection = () => {
    const [curriculums, setCurriculums] = useState([])
    useEffect(() => {
        getCurriculumApi().then((res) => {
            if (res?.data?.success === true) {
                setCurriculums(res?.data?.result)
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }, [])
    return (
        <>
            <div class="curriculum">
                <div class="cur-header">
                    <p class="text-xl font-semibold">Standard Curriculum</p>
                    <div
                        style={{ width: "120px", borderColor: "#ff6d9d" }}
                        class="flex justify-center items-center relative border mt-3"
                    >
                        <div
                            class="w-1/2 h-1 absolute"
                            style={{ backgroundColor: "#ff6d9d" }}
                        ></div>
                    </div>
                </div>
                <div class="cur-all">
                    {
                        curriculums && curriculums.length > 0 ? curriculums.map((curriculum) => {
                            return (
                                <div
                                    class="cur-container px-5 py-10 border flex flex-col justify-center items-center gap-5 hover:shadow-xl"
                                    style={{ borderColor: "#e7e1e4", background: curriculum?.bgColor }}
                                >
                                    <img
                                        src={curriculum?.image}
                                        style={{ width: "80px", height: "80px" }}
                                        alt={curriculum?.title}
                                    />
                                    <p class="font-medium">{curriculum?.title}</p>
                                    <p class="text-center text-xs text-neutral-600 font-normal">
                                        {curriculum?.description}
                                    </p>
                                </div>
                            )
                        }) : 
                        <p class="text-center text-neutral-600 font-normal">No Curriculum Found</p>
                    }
                </div>
            </div>
        </>
    )
}
