export const CountsSection = ({ institutionProfile }) => {
    return (
        <>
            <div class="w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <div
                    class="py-10 gap-y-2 flex flex-col justify-center items-center text-white px-8"
                    style={{ backgroundColor: "#39c9c9" }}
                >
                    <p class="text-6xl font-bold mb-2">{institutionProfile?.yearsOfExcellence ?? 0}</p>
                    <p class="text-base">Years of Excellence</p>
                </div>
                <div
                    class="py-10 gap-y-2 flex flex-col justify-center items-center text-white px-8"
                    style={{ backgroundColor: "#75cb75" }}
                >
                    <p class="text-6xl font-bold mb-2">{institutionProfile?.noOfStaffs ?? 0}</p>
                    <p class="text-base">No of Staffs</p>
                </div>
                <div
                    class="py-10 gap-y-2 flex flex-col justify-center items-center text-white px-8"
                    style={{ backgroundColor: "#ff9900" }}
                >
                    <p class="text-6xl font-semibold mb-2">{institutionProfile?.totalStudents ?? 0} +</p>
                    <p class="text-base">Students Enrolled</p>
                </div>
            </div>
        </>
    )
}