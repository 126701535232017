import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import Title from "../../components/admin-components/Title"
import { Button } from "../../components/Button"
import { addAboutUsApi, getAboutUsApi } from "../../apis/api"
import toast from "react-hot-toast"
import { ErrorHandler } from "../../components/error/errorHandler"
import { useEffect, useState } from "react"

export const AboutUs = () => {
    const [updated, setUpdated] = useState(false)
    const [vision, setVision] = useState('')
    const [mission, setMission] = useState('')
    const [motto, setMotto] = useState('')

    const aboutUsSchema = Yup.object().shape({
        vision: Yup.string().required("Required"),
        mission: Yup.string().required("Required"),
        motto: Yup.string().required("Required"),
    })

    async function addAboutUsContent(values) {
        await addAboutUsApi(values).then((res) => {
            if (res.data.success === true) {
                toast.success(res.data.message)
                setUpdated((prev) => !prev)
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }

    useEffect(() => {
        getAboutUsApi().then((res) => {
            if (res.data.success === true) {
                setVision(res.data.result.vision)
                setMission(res.data.result.mission)
                setMotto(res.data.result.motto)
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }, [updated])
    return (
        <>
            <main className="p-4">
                <div className="flex flex-col gap-2">
                    <Title title="Change About Us Settings" />
                    <p>By changing this settings it will affect everywhere.</p>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        vision: vision,
                        mission: mission,
                        motto: motto,
                    }}
                    validationSchema={aboutUsSchema}
                    onSubmit={(values) => {
                        addAboutUsContent(values)
                    }}
                >
                    {(props) => (
                        <Form className="flex flex-col gap-4 py-8">
                            <div className="grid gap-2">
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="vision"
                                        className="block font-medium"
                                    >
                                        Vision
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            as="textarea"
                                            type="text"
                                            name="vision"
                                            id="vision"
                                            autoComplete="vision"
                                            placeholder="Vision"
                                            className="appearance-none border rounded w-full min-h-24 p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.vision && props.touched.vision && (
                                        <p className="text-red-500 text-sm">{props.errors.vision}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="mission"
                                        className="block font-medium"
                                    >
                                        Mission
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            as="textarea"
                                            type="text"
                                            name="mission"
                                            id="mission"
                                            autoComplete="mission"
                                            placeholder="Mission"
                                            className="appearance-none border rounded w-full min-h-24 p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.mission && props.touched.mission && (
                                        <p className="text-red-500 text-sm">{props.errors.mission}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="motto"
                                        className="block font-medium"
                                    >
                                        Motto
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            as="textarea"
                                            type="text"
                                            name="motto"
                                            id="motto"
                                            autoComplete="motto"
                                            placeholder="motto"
                                            className="appearance-none border rounded w-full min-h-24 p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.motto && props.touched.motto && (
                                        <p className="text-red-500 text-sm">{props.errors.motto}</p>
                                    )}
                                </div>
                                <Button className={"w-max btn-primary"} type="submit">Save</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </main>
        </>
    )
}