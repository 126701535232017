import { Edit, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { deleteFaqsApi, getFaqsApi } from "../../apis/api";
import Title from "../../components/admin-components/Title";
import { ErrorHandler } from "../../components/error/errorHandler";
import { AddFAQsModal } from "./faqs-components/AddFAQsModal";
import { EditFAQsModal } from "./faqs-components/EditFAQsModal";

export const FAQs = () => {
    const [faqs, setFaqs] = useState([])
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [updated, setUpdated] = useState(false)
    const [selectedFAQ, setSelectedFAQ] = useState(null)

    useEffect(() => {
        getFaqsApi().then((res) => {
            if (res?.data?.success === true) {
                setFaqs(res?.data?.result)
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }, [updated])

    function deleteFaqs(id) {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#003366',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteFaqsApi(id).then((res) => {
                    if (res?.data?.success === true) {
                        Swal.fire({
                            icon: 'success',
                            title: 'FAQ deleted successfully',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        setUpdated((prev) => !prev)
                    }
                }).catch((err) => {
                    ErrorHandler(err)
                })
            }
        })
    }
    return (
        <>
            <main className="p-4">
                <div className="flex flex-col gap-2">
                    <Title title="All FAQs" />
                    <p>All FAQs will appear here.</p>
                </div>
                <div className="w-full flex item-center justify-end">
                    <button onClick={() => setShowAddModal(true)} className="btn-primary">Add FAQs</button>
                </div>
                <div class="relative overflow-auto mt-10">
                    <table class="w-full text-sm text-left text-neutral-700 bg-white">
                        <thead class="bg-neutral-100 text-sm text-neutral-700 uppercase sticky top-0">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    S.N
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Question
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Answer
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                faqs && faqs.length > 0 ? faqs.map((faq, index) => (
                                    <tr class="border-b hover:bg-white">
                                        <td class="px-6 py-4">
                                            {index + 1}
                                        </td>
                                        <td class="px-6 py-4">
                                            {faq?.question}
                                        </td>
                                        <td class="px-6 py-4">
                                            {faq?.answer}
                                        </td>
                                        <td class="px-6 py-4">
                                            <div className="flex items-center gap-2">
                                                <button onClick={() => { setSelectedFAQ(faq); setShowEditModal(true) }} className="icon-primary bg-blue-600 hover:bg-blue-600"><Edit size={16} /></button>
                                                <button onClick={() => deleteFaqs(faq?._id)} className="icon-primary bg-red-600 hover:bg-red-600"><Trash size={16} /></button>
                                            </div>
                                        </td>
                                    </tr>
                                )) :
                                    <tr class="border-b hover:bg-white">
                                        <td colSpan={4} className="text-center py-10 uppercase text-red-600 font-medium text-xl">No FAQs Found</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </main>
            <AddFAQsModal open={showAddModal} onClose={() => setShowAddModal(false)} setUpdated={setUpdated} />
            <EditFAQsModal open={showEditModal} onClose={() => setShowEditModal(false)} setUpdated={setUpdated} selectedFAQ={selectedFAQ} />
        </>
    )
}