import { useEffect, useState } from "react"
import { getAboutUsApi } from "../../../../apis/api"
import { ErrorHandler } from "../../../../components/error/errorHandler"
import { Loader } from "../../../../components/Loader"

export const AboutUsSection = () => {
    const [vision, setVision] = useState('')
    const [mission, setMission] = useState('')
    const [motto, setMotto] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getAboutUsApi().then((res) => {
            if (res.data.success === true) {
                setLoading(false)
                setVision(res.data.result.vision)
                setMission(res.data.result.mission)
                setMotto(res.data.result.motto)
            }
        }).catch((err) => {
            setLoading(false)
            ErrorHandler(err)
        })
    }, [])
    return (
        <>
            {
                loading ?
                    <Loader /> :
                    <div class="about_us">
                        <div class="about_cont">
                            <div class="about_cont_img"><img src="assets/icons/vision.svg" alt="" /></div>
                            <div class="about_cont_text">
                                <h3>Vision</h3>
                                <p>
                                    {vision ?? 'No data found'}
                                </p>
                            </div>
                        </div>
                        <div class="about_cont">
                            <div class="about_cont_img"><img src="assets/icons/mission.svg" alt="" /></div>
                            <div class="about_cont_text">
                                <h3>Mission</h3>
                                <p>
                                    {mission ?? 'No data found'}
                                </p>
                            </div>
                        </div>
                        <div class="about_cont">
                            <div class="about_cont_img"><img src="assets/icons/motto.svg" alt="" /></div>
                            <div class="about_cont_text">
                                <h3>Motto</h3>
                                <p>
                                    {motto ?? 'No data found'}
                                </p>
                            </div>
                        </div>
                    </div>

            }

        </>
    )
}