import { Link, useLocation } from "react-router-dom";

export const Navbar = ({ institutionProfile }) => {
    const location = useLocation();
    const desiredPaths = ["/login", "/admin/dashboard", "/admin/aboutus", "/admin/awards", "/admin/achievements", "/admin/curriculum", "/admin/stats", "/admin/principal-message", "/admin/youtube-link", "/admin/faqs", "/admin/glimpses"];
    const isDesiredPath = desiredPaths.includes(location.pathname);
    if (isDesiredPath) {
        return null;
    }
    return (
        <>
            <div
                className="flex flex-wrap justify-between w-full bg-red-600 px-6"
                id="home"
            >
                <div className="flex py-2 gap-x-4 gap-y-2 flex-grow justify-start flex-wrap">
                    <p className="flex gap-2 text-sm text-white items-center h-auto">
                        <i className="fa-solid fa-location-dot"></i>
                        {institutionProfile?.location}
                    </p>
                    <p className="flex gap-2 text-sm text-white items-center">
                        <i className="fa-solid fa-envelope"></i>
                        {institutionProfile?.email}
                    </p>
                    <p className="flex gap-2 text-sm text-white items-center">
                        <i className="fa-solid fa-phone"></i>
                        {institutionProfile?.number}
                    </p>
                </div>
                <div className="flex gap-3 items-center text-white">
                    <a href={institutionProfile?.facebookLink}>
                        <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a href={`mailto:${institutionProfile?.email}`}>
                        <i className="fa-solid fa-envelope"></i>
                    </a>
                    <a
                        target="_blank"
                        href={institutionProfile?.locationForMap}
                    >
                        <i className="fa-solid fa-location-dot"></i>
                    </a>
                </div>
            </div>
            <nav className="shadow-md w-full">
                <input type="checkbox" id="check" />
                <label for="check" className="checkbtn">
                    <i className="fas fa-bars" style={{ color: "#003366" }}></i>
                </label>
                <div className="flex gap-2 items-center font-bold">
                    <Link className="logo" href="/"><img src="assets/images/k-logo.png" alt="Logo" /></Link>
                    <p style={{ color: "#192243" }}>Mount View English <br /> Boarding School</p>
                </div>
                <ul>
                    <li><a className="list-group-item" href="#home">Home</a></li>
                    <li><a className="list-group-item" href="#about">About us</a></li>
                    <li><a className="list-group-item" href="#curriculum">Standard Curriculum</a>
                    </li>
                    <li><a href="#faq" className="list-group-item">FAQ</a></li>
                    <li>
                        <a href="#contact" className="list-group-item">Contact Us</a>
                    </li>
                </ul>
            </nav>
        </>
    )
}