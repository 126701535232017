import { Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import { addAwardContentApi, getAwardContentApi } from "../../apis/api"
import Title from "../../components/admin-components/Title"
import { Button } from "../../components/Button"
import { ErrorHandler } from "../../components/error/errorHandler"

export const Awards = () => {
    const [image, setImage] = useState('')
    const [previewImage, setPreviewImage] = useState('')
    const [responseImage, setResponseImage] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [updated, setUpdated] = useState(false)

    const awardsSchema = Yup.object().shape({
        title: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
    })

    function addAwards(values) {
        const data = {
            title: values.title,
            description: values.description,
            image: image
        }
        addAwardContentApi(data).then((res) => {
            if (res.data.success === true) {
                toast.success(res?.data?.message)
                setUpdated((prev) => !prev)
            }
        }).catch((err) => {
            ErrorHandler(err)
        })
    }

    useEffect(() => {
        getAwardContentApi().then((res) => {
            if (res.data.success === true) {
                setResponseImage(res?.data?.result?.image)
                setTitle(res?.data?.result?.title)
                setDescription(res?.data?.result?.description)
            }
        })
    }, [updated])

    return (
        <>
            <main className="p-4">
                <div className="flex flex-col gap-2">
                    <Title title="Change Awards Settings" />
                    <p>By changing this settings it will affect everywhere.</p>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        title: title,
                        description: description,
                    }}
                    validationSchema={awardsSchema}
                    onSubmit={(values) => {
                        addAwards(values)
                    }}
                >
                    {(props) => (
                        <Form className="flex flex-col gap-4 py-8">
                            <div className="grid gap-4">
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="title"
                                        className="block font-medium"
                                    >
                                        Title
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            type="text"
                                            name="title"
                                            id="title"
                                            autoComplete="title"
                                            placeholder="Title"
                                            className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.title && props.touched.title && (
                                        <p className="text-red-500 text-sm">{props.errors.title}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="description"
                                        className="block font-medium"
                                    >
                                        Description
                                    </label>
                                    <div className="mt-1">
                                        <Field
                                            as="textarea"
                                            type="text"
                                            name="description"
                                            id="description"
                                            autoComplete="description"
                                            placeholder="Description"
                                            className="appearance-none border rounded w-full min-h-36 p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                    {props.errors.description && props.touched.description && (
                                        <p className="text-red-500 text-sm">{props.errors.description}</p>
                                    )}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label
                                        htmlFor="image"
                                        className="block font-medium"
                                    >
                                        Image
                                    </label>
                                    <Field
                                        type="file"
                                        name="image"
                                        id="image"
                                        className="appearance-none border rounded w-full p-3 text-neutral-700 leading-tight focus:outline-none focus:shadow-outline"
                                        onChange={(e) => {
                                            setImage(e.target.files[0])
                                            setPreviewImage(URL.createObjectURL(e.target.files[0]))
                                        }}
                                    />
                                </div>
                                {previewImage && (
                                    <>
                                        <p>Preview Image</p>
                                        <a className="w-max" target="_blank" href="">
                                            <img
                                                src={previewImage}
                                                alt="Preview"
                                                className="h-32 w-56"
                                            />
                                        </a>
                                    </>
                                )}
                                {responseImage && (
                                    <>
                                        <p>Current Image</p>
                                        <a className="w-max" target="_blank" href={responseImage}>
                                            <img
                                                src={responseImage}
                                                alt="Preview"
                                                className="h-32 w-56"
                                            />
                                        </a>
                                    </>
                                )}
                                <Button className={"w-max btn-primary"} type="submit">Save</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </main>
        </>
    )
}