import { CircleCheck, CircleX, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import { deleteGlimpseApi, getGlimpseApi, updateGlimpseStatusApi } from "../../apis/api";
import Title from "../../components/admin-components/Title";
import { ErrorHandler } from "../../components/error/errorHandler";
import { AddImageModal } from "./glimpses-components/AddImageModal";
import ReactPaginate from "react-paginate";

export const Glimpses = () => {
    const [showModal, setShowModal] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [images, setImages] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const limit = 10

    async function getAllGlimpses() {
        await getGlimpseApi(pageCount, limit).then((res) => {
            if (res?.data?.success) {
                setImages(res?.data?.result?.content);
                setTotalPages(res?.data?.result?.totalPages);
            }
        }).catch((err) => {
            ErrorHandler(err);
        })
    }

    useEffect(() => {
        getAllGlimpses();
    }, [updated])

    function handleDelete(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#003366',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteGlimpseApi(id).then((res) => {
                    if (res?.data?.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: res?.data?.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        setUpdated((prev) => !prev);
                    }
                }).catch((err) => {
                    ErrorHandler(err);
                })
            }
        })
    }

    async function handleChangeImageStatus(id, status) {
        const data = {
            active: status
        }
        Swal.fire({
            title: 'Are you sure you want to change active status?',
            text: "Don't worry you can always change it later.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#003366',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await updateGlimpseStatusApi(id, data).then((res) => {
                    if (res?.data?.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Changes!',
                            text: res?.data?.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        setUpdated((prev) => !prev);
                    }
                }).catch((err) => {
                    ErrorHandler(err);
                })
            }
        })
    }

    const handlePageClick = ({ selected }) => {
        setPageCount(selected + 1);
        setUpdated((prev) => !prev);
    }

    return (
        <>
            <main className="p-4">
                <div className="flex flex-col gap-2">
                    <Title title="All Glimpses" />
                    <p>All images will appear here.</p>
                </div>
                <div className="w-full flex item-center justify-end">
                    <button className="btn-primary" onClick={() => setShowModal(true)}>Add Images</button>
                </div>
                <div class="relative overflow-auto mt-10">
                    <table class="w-full text-sm text-left text-neutral-700 bg-white">
                        <thead class="bg-neutral-100 text-sm text-neutral-700 uppercase sticky top-0">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    S.N
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                images && images.length > 0 ? images?.map((result, index) => (
                                    <tr class="border-b hover:bg-white">
                                        <td class="px-6 py-4">
                                            {index + 1}
                                        </td>
                                        <td class="px-6 py-4">
                                            <img className="w-[80px]" src={result?.image} alt="Image" />
                                        </td>
                                        <td class="px-6 py-4">
                                            {
                                                result?.active === true ?
                                                    <div className="w-[100px] h-[40px] bg-green-600 uppercase text-white font-bold flex justify-center items-center">active</div>
                                                    :
                                                    <div className="w-[100px] h-[40px] bg-red-600 uppercase text-white font-bold flex justify-center items-center">inactive</div>
                                            }
                                        </td>
                                        <td class="px-6 py-4">
                                            <div className="flex items-center gap-2">
                                                {
                                                    result?.active === true ?
                                                        <button onClick={() => handleChangeImageStatus(result?._id, false)} className="icon-primary bg-red-600 hover:bg-red-600"><CircleX size={16} /></button> :
                                                        <button onClick={() => handleChangeImageStatus(result?._id, true)} className="icon-primary bg-green-600 hover:bg-green-600"><CircleCheck size={16} /></button>
                                                }
                                                <button onClick={() => handleDelete(result?._id)} className="icon-primary bg-red-600 hover:bg-red-600"><Trash size={16} /></button>
                                            </div>
                                        </td>
                                    </tr>
                                )) :
                                    <tr class="border-b hover:bg-white">
                                        <td colSpan={4} className="text-center py-10 uppercase text-red-600 font-medium text-xl">No Glimpses Found</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                    <ReactPaginate
                        className="flex items-center border rounded-lg text-neutral-700 my-2 p-auto w-max mt-5"
                        pageCount={totalPages}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination flex'}
                        previousLabel={<span className="flex items-center justify-center text-neutral-700 px-2 py-1">Previous</span>}
                        nextLabel={<span className="flex items-center justify-center text-neutral-700 px-2 py-1">Next</span>}
                        pageClassName="flex items-center justify-center px-2 py-1 border-l hover:bg-neutral-100"
                        breakClassName="flex items-center justify-center px-2 py-1 border-l hover:bg-neutral-100"
                        activeClassName="bg-primary/5 text-white"
                        disabledClassName="pagination__link--disabled pointer-events-none text-gray-400 cursor-not-allowed"
                        previousClassName="flex items-center justify-center px-2 py-1 hover:bg-neutral-200"
                        nextClassName="flex items-center justify-center px-2 py-1 border-l hover:bg-neutral-100"
                        pageLinkClassName="flex items-center justify-center px-2 py-1"
                        breakLinkClassName="flex items-center justify-center px-2 py-1"
                        activeLinkClassName="text-primary"
                    />
                </div>
                <AddImageModal open={showModal} onClose={() => setShowModal(false)} setUpdated={setUpdated} />
            </main>
        </>
    )
}