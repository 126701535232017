import { useEffect, useState } from "react"
import { getAchievementsApi, getAwardContentApi } from "../../../../apis/api"

export const AwardSection = () => {
    const [responseImage, setResponseImage] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [achivementsResponseImage, setAchivementsResponseImage] = useState("")
    const [achivementsTitle, setAchivementsTitle] = useState("")
    const [achivementsDescription, setAchivementsDescription] = useState("")

    useEffect(() => {
        getAwardContentApi().then((res) => {
            if (res.data.success === true) {
                setResponseImage(res?.data?.result?.image)
                setTitle(res?.data?.result?.title)
                setDescription(res?.data?.result?.description)
            }
        })
    }, [])

    useEffect(() => {
        getAchievementsApi().then((res) => {
            if (res.data.success === true) {
                setAchivementsResponseImage(res?.data?.result?.image)
                setAchivementsTitle(res?.data?.result?.title)
                setAchivementsDescription(res?.data?.result?.description)
            }
        })
    }, [])

    return (
        <>
            <div class="award">
                <div class="award_cont" id="award_cont1">
                    <div class="award_img"><img src={responseImage} alt="Award" /></div>
                    <div class="award_text">
                        <h2>{title}</h2>
                        <p>
                            {description}
                        </p>
                    </div>
                </div>
                <div class="award_cont award_cont_below">
                    <div class="award_text">
                        <h2>{achivementsTitle}</h2>
                        <p>
                            {achivementsDescription}
                        </p>
                    </div>
                    <div class="award_img">
                        <img class="rounded-md" src={achivementsResponseImage} alt="Achivements" />
                    </div>
                </div>
                <div id="curriculum"></div>
            </div>
        </>
    )
}